import { createGlobalStyle } from "styled-components"
import styledNormalize from "styled-normalize"

import FontFaces from "./fonts"

import { mixins } from "@styles"

export const GlobalStyle = createGlobalStyle`
  ${styledNormalize}
  ${FontFaces}

  :root {
    --white: #ffffff;
    --black: #000000;
    --darkcharcoal: #333333;
    --whitesmoke: #d7d7d8;
    --darkgray: #161716;
  }

  body {
    --blue: #007aff;
    --green: #34c759;
    --indigo: #5856d6;
    --orange: #ff9500;
    --pink: #ff2d55;
    --purple: #af52de;
    --red: #ff3b30;
    --teal: #5ac8ff;
    --yellow: #ffcc00;

    background-color: var(--white);
    color: var(--darkcharcoal);

    &.animate {
      ${mixins.transition("background-color")}
    }
  }

  body.dark {
    --blue: #0a84ff;
    --green: #30d158;
    --indigo: #5e5ce6;
    --orange: #ff9f0a;
    --pink: #ff375f;
    --purple: #bf5af2;
    --red: #ff453a;
    --teal: #64d2ff;
    --yellow: #ffd600;

    background-color: var(--darkgray);
    color: var(--whitesmoke);
  }

  html {
    box-sizing: border-box;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  ::selection {
    background: var(--yellow);
  }

  html,
  body {
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: Apercu, Source Sans Pro, system, system-ui, -apple-system, BlinkMacSystemFont, Roboto, Helvetica, Arial, sans-serif;
    line-height: 1.5;
    width: 100%;
  }

  a {
    color: inherit;
    font-weight: 700;
    &:hover, &:focus {
      text-decoration: none;
    }
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  svg {
    width: 100%;
    height: 100%;
    fill: currentColor;
  }

  img {
    max-width: 100%;
    vertical-align: middle;
  }

  strong {
    font-weight: 700;
  }

  .small {
    font-family: 'Inconsolata', monospace;
    font-size: 0.85rem;
    display: inline-block;
    margin-top: 15px;
    &:after {
      display: none;
    }
  }
`
