import styled from "styled-components"

const UnderlineLink = styled.a`
  position: relative;
  display: inline-block;
  text-decoration: none;
  &:after {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 1px;
    background: currentColor;
    content: "";
    opacity: 0;
    transition: height 0.3s, opacity 0.3s, transform 0.3s;
    transform: translateY(-0.3em);
  }
  &:hover,
  &:focus {
    text-decoration: none;
    &:after {
      height: 2px;
      border-radius: 3px;
      opacity: 1;
      transform: translateY(-0.2em);
    }
  }
`

export default UnderlineLink
