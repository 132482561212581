export function transition(property = "all") {
  const properties = Array.isArray(property)
    ? property
    : property.split(",").map(p => p.trim())
  return `
    transition-duration: 0.2s;
    transition-timing-function: ease-in-out;
    transition-property: ${properties.join(", ")};
  `
}

export default {
  transition,
}
