import { css } from "styled-components"

const sizes = {
  fifteenInch: 1440,
  thirteenInch: 1280,
  desktop: 1024,
  littleDesktop: 850,
  tablet: 768,
  weirdNotTablet: 630,
  phablet: 550,
  mobile: 480,
  smallMobile: 360,
  tinyMobile: 330,
}

export const breakpoints = Object.keys(sizes).reduce((accumulator, label) => {
  const emSize = sizes[label] / 16
  accumulator[label] = (...args) => css`
    @media (max-width: ${emSize}em) {
      ${css(...args)}
    }
  `
  return accumulator
}, {})

export default breakpoints
