import { css } from "styled-components"

import ApercuLightEOT from "@fonts/apercu/Apercu-Light.eot"
import ApercuLightWOFF from "@fonts/apercu/Apercu-Light.woff"
import ApercuLightWOFF2 from "@fonts/apercu/Apercu-Light.woff2"
import ApercuLightTTF from "@fonts/apercu/Apercu-Light.ttf"
import ApercuLightSVG from "@fonts/apercu/Apercu-Light.svg"
import ApercuLightItalicEOT from "@fonts/apercu/Apercu-LightItalic.eot"
import ApercuLightItalicWOFF from "@fonts/apercu/Apercu-LightItalic.woff"
import ApercuLightItalicWOFF2 from "@fonts/apercu/Apercu-LightItalic.woff2"
import ApercuLightItalicTTF from "@fonts/apercu/Apercu-LightItalic.ttf"
import ApercuLightItalicSVG from "@fonts/apercu/Apercu-LightItalic.svg"
import ApercuRegularEOT from "@fonts/apercu/Apercu-Regular.eot"
import ApercuRegularWOFF from "@fonts/apercu/Apercu-Regular.woff"
import ApercuRegularWOFF2 from "@fonts/apercu/Apercu-Regular.woff2"
import ApercuRegularTTF from "@fonts/apercu/Apercu-Regular.ttf"
import ApercuRegularSVG from "@fonts/apercu/Apercu-Regular.svg"
import ApercuItalicEOT from "@fonts/apercu/Apercu-Italic.eot"
import ApercuItalicWOFF from "@fonts/apercu/Apercu-Italic.woff"
import ApercuItalicWOFF2 from "@fonts/apercu/Apercu-Italic.woff2"
import ApercuItalicTTF from "@fonts/apercu/Apercu-Italic.ttf"
import ApercuItalicSVG from "@fonts/apercu/Apercu-Italic.svg"
import ApercuMediumEOT from "@fonts/apercu/Apercu-Medium.eot"
import ApercuMediumWOFF from "@fonts/apercu/Apercu-Medium.woff"
import ApercuMediumWOFF2 from "@fonts/apercu/Apercu-Medium.woff2"
import ApercuMediumTTF from "@fonts/apercu/Apercu-Medium.ttf"
import ApercuMediumSVG from "@fonts/apercu/Apercu-Medium.svg"
import ApercuMediumItalicEOT from "@fonts/apercu/Apercu-MediumItalic.eot"
import ApercuMediumItalicWOFF from "@fonts/apercu/Apercu-MediumItalic.woff"
import ApercuMediumItalicWOFF2 from "@fonts/apercu/Apercu-MediumItalic.woff2"
import ApercuMediumItalicTTF from "@fonts/apercu/Apercu-MediumItalic.ttf"
import ApercuMediumItalicSVG from "@fonts/apercu/Apercu-MediumItalic.svg"
import ApercuBoldEOT from "@fonts/apercu/Apercu-Bold.eot"
import ApercuBoldWOFF from "@fonts/apercu/Apercu-Bold.woff"
import ApercuBoldWOFF2 from "@fonts/apercu/Apercu-Bold.woff2"
import ApercuBoldTTF from "@fonts/apercu/Apercu-Bold.ttf"
import ApercuBoldSVG from "@fonts/apercu/Apercu-Bold.svg"
import ApercuBoldItalicEOT from "@fonts/apercu/Apercu-BoldItalic.eot"
import ApercuBoldItalicWOFF from "@fonts/apercu/Apercu-BoldItalic.woff"
import ApercuBoldItalicWOFF2 from "@fonts/apercu/Apercu-BoldItalic.woff2"
import ApercuBoldItalicTTF from "@fonts/apercu/Apercu-BoldItalic.ttf"
import ApercuBoldItalicSVG from "@fonts/apercu/Apercu-BoldItalic.svg"

const FontFaces = css`
  @font-face {
    font-family: "Apercu";
    src: url(${ApercuLightEOT});
    src: url(${ApercuLightEOT + "?#iefix"}) format("embedded-opentype"),
      url(${ApercuLightWOFF2}) format("woff2"),
      url(${ApercuLightWOFF}) format("woff"),
      url(${ApercuLightTTF}) format("truetype"),
      url(${ApercuLightSVG + "#Apercu-Light"}) format("svg");
    font-weight: 300;
    font-style: normal;
  }
  @font-face {
    font-family: "Apercu";
    src: url(${ApercuLightItalicEOT});
    src: url(${ApercuLightItalicEOT + "?#iefix"}) format("embedded-opentype"),
      url(${ApercuLightItalicWOFF2}) format("woff2"),
      url(${ApercuLightItalicWOFF}) format("woff"),
      url(${ApercuLightItalicTTF}) format("truetype"),
      url(${ApercuLightItalicSVG + "#Apercu-LightItalic"}) format("svg");
    font-weight: 300;
    font-style: italic;
  }
  @font-face {
    font-family: "Apercu";
    src: url(${ApercuRegularEOT});
    src: url(${ApercuRegularEOT + "?#iefix"}) format("embedded-opentype"),
      url(${ApercuRegularWOFF2}) format("woff2"),
      url(${ApercuRegularWOFF}) format("woff"),
      url(${ApercuRegularTTF}) format("truetype"),
      url(${ApercuRegularSVG + "#Apercu-Regular"}) format("svg");
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: "Apercu";
    src: url(${ApercuItalicEOT});
    src: url(${ApercuItalicEOT + "?#iefix"}) format("embedded-opentype"),
      url(${ApercuItalicWOFF2}) format("woff2"),
      url(${ApercuItalicWOFF}) format("woff"),
      url(${ApercuItalicTTF}) format("truetype"),
      url(${ApercuItalicSVG + "#Apercu-Italic"}) format("svg");
    font-weight: 400;
    font-style: italic;
  }
  @font-face {
    font-family: "Apercu";
    src: url(${ApercuMediumEOT});
    src: url(${ApercuMediumEOT + "?#iefix"}) format("embedded-opentype"),
      url(${ApercuMediumWOFF2}) format("woff2"),
      url(${ApercuMediumWOFF}) format("woff"),
      url(${ApercuMediumTTF}) format("truetype"),
      url(${ApercuMediumSVG + "#Apercu-Medium"}) format("svg");
    font-weight: 500;
    font-style: normal;
  }
  @font-face {
    font-family: "Apercu";
    src: url(${ApercuMediumItalicEOT});
    src: url(${ApercuMediumItalicEOT + "?#iefix"}) format("embedded-opentype"),
      url(${ApercuMediumItalicWOFF2}) format("woff2"),
      url(${ApercuMediumItalicWOFF}) format("woff"),
      url(${ApercuMediumItalicTTF}) format("truetype"),
      url(${ApercuMediumItalicSVG + "#Apercu-MediumItalic"}) format("svg");
    font-weight: 500;
    font-style: italic;
  }
  @font-face {
    font-family: "Apercu";
    src: url(${ApercuBoldEOT});
    src: url(${ApercuBoldEOT + "?#iefix"}) format("embedded-opentype"),
      url(${ApercuBoldWOFF2}) format("woff2"),
      url(${ApercuBoldWOFF}) format("woff"),
      url(${ApercuBoldTTF}) format("truetype"),
      url(${ApercuBoldSVG + "#Apercu-Bold"}) format("svg");
    font-weight: 700;
    font-style: normal;
  }
  @font-face {
    font-family: "Apercu";
    src: url(${ApercuBoldItalicEOT});
    src: url(${ApercuBoldItalicEOT + "?#iefix"}) format("embedded-opentype"),
      url(${ApercuBoldItalicWOFF2}) format("woff2"),
      url(${ApercuBoldItalicWOFF}) format("woff"),
      url(${ApercuBoldItalicTTF}) format("truetype"),
      url(${ApercuBoldItalicSVG + "#Apercu-BoldItalic"}) format("svg");
    font-weight: 700;
    font-style: italic;
  }
`

export default FontFaces
