import React, { Component } from "react"
import styled from "styled-components"

import { breakpoints, mixins } from "@styles"
import PointingUpEmojiIcon from "@images/emojis/pointing-up.png"

const StyledContainer = styled.button`
  ${mixins.transition("padding-bottom")}
  background: transparent;
  border: none;
  outline: none;
  width: 40px;
  position: fixed;
  bottom: 25px;
  right: 10px;
  cursor: pointer;
  display: ${props => (props.visible ? "block" : "none")};

  ${breakpoints.desktop`
    bottom: 20px;
    right: 7px;
    width: 35px;
  `}
  ${breakpoints.mobile`
    bottom: 15px;
    right: 3px;
  `}

  &:hover, &:focus {
    padding-bottom: 10px;
  }

  img {
    width: 100%;
  }
`

class TopButton extends Component {
  state = { isVisible: false }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll)
  }

  handleScroll = () => {
    const scrollTop = document.documentElement.scrollTop
    if (scrollTop > 100 && !this.state.isVisible) {
      this.setState({ isVisible: true })
    } else if (scrollTop <= 100 && this.state.isVisible) {
      this.setState({ isVisible: false })
    }
  }

  onClick = () => {
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <StyledContainer onClick={this.onClick} visible={this.state.isVisible}>
        <img src={PointingUpEmojiIcon} alt="Back to Top" />
      </StyledContainer>
    )
  }
}

export default TopButton
