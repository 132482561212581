import styled from "styled-components"

import { breakpoints } from "@styles"

const Section = styled.section`
  display: flex;
  justify-content: center;
  padding: 100px 170px;

  ${breakpoints.thirteenInch`padding: 100px;`}
  ${breakpoints.desktop`padding: 50px;`}
  ${breakpoints.tablet`display: block;`}
  ${breakpoints.mobile`padding: 50px 35px;`}
`

Section.Title = styled.div`
  flex-shrink: 0;
  width: 200px;
  margin-right: 70px;
  color: var(--blue);
  body.dark & {
    color: var(--blue);
  }
  text-transform: uppercase;
  text-align: right;
  font-weight: 700;
  letter-spacing: 2px;

  ${breakpoints.desktop`margin-right: 50px;`}
  ${breakpoints.littleDesktop`width: 150px;`}
  ${breakpoints.tablet`text-align: left; width: auto;`}
`

Section.Content = styled.div`
  font-weight: 300;
  font-size: 1rem;
  width: 100%;
  max-width: 650px;

  ${breakpoints.tablet`padding-top: 30px; padding-left: 50px;`}
  ${breakpoints.mobile`padding-left: 30px;`}

  p {
    margin-top: 0;
    margin-bottom: 25px;
  }
`

export default Section
