import React, { useState, useEffect } from "react"
import styled from "styled-components"
import uniqueId from "lodash/uniqueId"

import { breakpoints, mixins } from "@styles"

import SunIcon from "@images/switch/sun.svg"
import SunWhiteIcon from "@images/switch/sun-white.svg"
import MoonIcon from "@images/switch/moon.svg"
import MoonWhiteIcon from "@images/switch/moon-white.svg"

const SwitchWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 20px;
  right: 30px;
  ${breakpoints.weirdNotTablet`position: absolute;`}
  ${breakpoints.mobile`top: 10px; right: 20px;`}
`

const StyledSun = styled.div`
  width: 17px;
  height: 17px;
  background-image: url(${SunIcon});
  body.dark & {
    background-image: url(${SunWhiteIcon});
  }
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
`

const StyledMoon = styled.div`
  width: 17px;
  height: 17px;
  background-image: url(${MoonIcon});
  body.dark & {
    background-image: url(${MoonWhiteIcon});
  }
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
`

const StyledToggleWrapper = styled.div`
  margin: 10px;
`

const StyledLabel = styled.label`
  cursor: pointer;
  text-indent: -9999px;
  width: 45px;
  height: 25px;
  background: grey;
  display: block;
  border-radius: 100px;
  position: relative;
  &:after {
    ${mixins.transition("left, transform")}
    content: '';
    position: absolute;
    top: 3px;
    left: 3px;
    width: 19px;
    height: 19px;
    background: #fff;
    border-radius: 100%;
  }
`

const StyledSwitch = styled.input`
  height: 0;
  width: 0;
  visibility: hidden;
  position: absolute;
  &:checked + ${StyledLabel} {
    background: var(--green);
    &:after {
      left: calc(100% - 3px);
      transform: translateX(-100%);
    }
  }
`

const Switch = () => {
  let websiteTheme
  if (typeof window !== "undefined") {
    websiteTheme = window.__theme
  }

  const [theme, setTheme] = useState(websiteTheme)

  useEffect(() => {
    setTheme(window.__theme)
    window.__onThemeChange = () => {
      setTheme(window.__theme)
    }
  }, [])

  const toggleTheme = e => {
    window.__setPreferredTheme(e.target.checked ? "dark" : "light")
  }

  // Generate a unique id for the switch input
  const [id] = useState(() => uniqueId("switch-"))

  return (
    <SwitchWrapper>
      <StyledSun />
      <StyledToggleWrapper>
        <StyledSwitch
          id={id}
          type="checkbox"
          onChange={toggleTheme}
          checked={theme === "dark"}
        />
        <StyledLabel htmlFor={id}>Toggle</StyledLabel>
      </StyledToggleWrapper>
      <StyledMoon />
    </SwitchWrapper>
  )
}

export default Switch
